<template>
  <div class="win-jackpot-animation">
    <SpritesheetAnimation id="win-jackpot-sprite"
        :spritesheet="require('@/assets/images/animations/win-jackpot.png')"
        :json="require('@/assets/images/animations/win-jackpot.json')"
        :yoyo="false"
        :fps="19"
        :autoplay="false"
        :required-height="262"
        ref="winJackpotAnimationSprite"
    />
  </div>
</template>

<script>
import SpritesheetAnimation from "../../Utils/SpritesheetAnimation";

export default {
  name: "WinJackpotAnimation",
  components: { SpritesheetAnimation }
}
</script>

<style scoped lang="scss"></style>
