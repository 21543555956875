<template>
  <div class="win-jackpot-animation-sprite">
    <WinJackpotAnimationSprite ref="winJackpotAnimationSprite"/>
  </div>
</template>

<script>
import WinJackpotAnimationSprite from '@/components/Misc/Animations/WinJackpotAnimation'

export default {
  name: "WinChipAnimation",
  components: {
    WinJackpotAnimationSprite
  },
  mounted() {
    this.$refs.winJackpotAnimationSprite.$refs.winJackpotAnimationSprite.playOnce()
  }
}
</script>

<style scoped lang="scss">
.win-jackpot-animation-sprite{
  position:absolute;
  z-index:5;
  top:-50px;
  left:-100px;
}

.seat-index-0,
.seat-index-1,
.seat-index-2,
.seat-index-8{
  .win-jackpot-animation-sprite{
    left:unset;
    right:-60px;
  }
}
</style>
